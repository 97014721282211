import React from "react";
import { GlobalStyles } from "../styles/global";
import { theme } from "../styles/theme";
import { ThemeProvider } from "styled-components";
import { Link } from "react-router-dom";

function SocialLink({ link, img_path }) {
  return (
    <a
      href={link}
      style={{
        color: "white",
        marginRight: "0.5rem",
        marginLeft: "0.5rem",
      }}
    >
      <img
        alt={"social link"}
        src={img_path}
        href={link}
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    </a>
  );
}

function Social() {
  let linkedin = process.env.PUBLIC_URL + "/companies/linkedin.png";
  let github = process.env.PUBLIC_URL + "/companies/github.png";
  let gscholar = process.env.PUBLIC_URL + "/companies/gscholar.png";

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Link
          style={{
            color: "white",
            marginRight: "0.5rem",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
          to={"/"}
        >
          {" "}
          PIETRO BONAZZI{" "}
        </Link>
        <SocialLink
          link="https://linkedin.com/in/pietrobonazzi"
          img_path={linkedin}
        />
        <SocialLink link="https://github.com/pbonazzi" img_path={github} />
        <SocialLink
          link="https://scholar.google.com/citations?user=AjGv99UAAAAJ&hl=it"
          img_path={gscholar}
        />
      </div>
    </ThemeProvider>
  );
}
export default Social;
