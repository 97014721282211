import React from "react";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";
import { ThemeProvider } from "styled-components";

import SecondaryNews from "../../components/News/SecondaryNews/SecondaryNews";
import { news } from "../../data/news_list";

import StudentProject from "../StudentProject";
import { studentprojects } from "../../data/studentproject_list";

import styled from "styled-components";

const StyledSection = styled.div` 
    padding: 30px;
    background-color: white;
    border-radius: 10px; 
`;

const StyledProjectsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
`;


function FirstPage() {
    const newsItems = news.map((item) => (
        <SecondaryNews
            key={item.name}
            name={item.name}
            title={item.title}
            description={item.description}
            website={item.website}
        />
    ));



    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div style={{ display: "flex", flexDirection: "column", padding: 30, justifyContent: "center", backgroundColor: "white" }}>

                <div
                    style={{
                        width: "100%",
                        height: "500px", // Adjust height as needed for cropping
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/aboutme.jpeg"})`,
                        backgroundSize: "cover",
                        marginBottom: "30px",
                        backgroundPosition: "center",
                        opacity: 0.7, // Adjust for transparency
                        borderRadius: "10px", // Optional: Add rounded corners 
                    }}
                ></div>

                {/* Biography Section */}
                <div
                    style={{
                        backgroundColor: "black", // Light background for contrast
                        padding: "40px",
                        borderRadius: "10px",
                        marginBottom: "30px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: Add subtle shadow
                    }}
                >
                    <h3 style={{ fontSize: "23px", marginBottom: "15px", color: "white", textAlign: "left" }}>BIOGRAPHY</h3>
                    <p style={{ fontSize: "16px", lineHeight: "1.6", color: "white", textAlign: "justify" }}>
                        Hello, I am interested in low-latency, efficient machine learning solutions. Currently, I am working as a PhD @ ETH Zürich and as a freelancer machine learning consultant for LAT and NEWNOW.
                        During my MSc., I worked on differentiable rendering, graph-based transformers and diffusion with Federico Tombari's group at Google.
                    </p>
                </div>

                <StyledSection>
                    <h2 style={{ fontSize: "23px", marginBottom: "30px", textAlign: "center", color: "black" }}>Student Projects</h2>
                    <StyledProjectsSection>
                        {studentprojects.map((project, index) => (
                            <StudentProject key={index} {...project} />
                        ))}
                    </StyledProjectsSection>
                </StyledSection>

                <StyledSection>
                    <h2 style={{ fontSize: "23px", marginBottom: "30px", textAlign: "center", color: "black", }}>News & Announcements</h2>

                    <StyledProjectsSection>
                        {newsItems}
                    </StyledProjectsSection>



                </StyledSection>
            </div>



        </ThemeProvider >
    );
}
export default FirstPage; 